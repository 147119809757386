<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("device.stock.text"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      stockinfo: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",
      jurisdiction: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      deviceid: 0,
    };
  },
  mounted() {
    this.title = this.$t("device.stock.text");
    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.devices.lists"),
        href: "/device/list",
      },
      {
        text: this.$t("menuitems.devices.devicedetail"),
        href: "/device/devicedetail?id=" + this.$route.query.id,
      },
      {
        text: this.$t("device.stock.text"),
        active: true,
      },
    ];
    //   this.titleTemplate(this.$t('menuitems.devices.lists'))
    this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"));
    this.activeapp = JSON.parse(sessionStorage.getItem("apps"));
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getstockinfo();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.deviceid = this.$route.query.id;
    //   this.page.title=this.$t('menuitems.devices.lists')
    console.log(this.$t('device.stock.notice'))
  },
  methods: {
    getstockinfo() {
      var that = this;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "getstocksetting",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.stockinfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    resetstock(id){

    },
    resetstockv2(id){

    },
    changestock(id,save){

    },
    changenoticevalue(id,save){

    }
  },
  filters: {
      formatTimer: function(value) {
        value=value*1000
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        return y + "-" + MM + "-" + d + " " + h + ":" + m;
      }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table">
              <thead>
                <tr>
                  <td>{{ $t('device.stock.no') }}</td>
                  <td>{{ $t('device.stock.settings') }}</td>
                  <td>{{ $t('device.stock.free') }}</td>
                  <td>{{ $t('device.stock.resets') }}</td>
                  <td>{{ $t('device.stock.action') }}</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in stockinfo" :key="idx">
                  <td>{{ val.no }}</td>
                  <td>{{ val.stock_save }}</td>
                  <td>{{ val.stock }}</td>
                  <td>{{ val.reset | formatTimer }}</td>
                  <td>
                    <a
                      class="btn btn-info rounded-pill btn-sm"
                      @click="changestock(val.id, val.stock_save)"
                      v-if="
                        jurisdiction.indexOf('devices-info-stock-change') >= 0
                      "
                      >{{ $t('device.stock.change') }}</a
                    >
                    <a
                      class="btn btn-info rounded-pill btn-sm"
                      @click="changenoticevalue(val.id, val.save)"
                      v-if="val.codes == 7620 && val.no == 5"
                    >
                      {{ $t('device.stock.notice') }}
                    </a>
                    <a
                      class="btn btn-info rounded-pill btn-sm"
                      v-if="
                        val.codes == 7620 &&
                        val.no != 5 &&
                        jurisdiction.indexOf('devices-info-stock-reset') >= 0
                      "
                      @click="resetstockv2(val.id)"
                      >{{ $t('device.stock.reset') }}</a
                    >
                    <a
                      class="btn btn-info rounded-pill btn-sm"
                      @click="resetstock(val.id)"
                      v-if="
                        val.codes != 7620 &&
                        jurisdiction.indexOf('devices-info-stock-reset') >= 0
                      "
                      >{{ $t('device.stock.reset') }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>