var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('device.stock.no')))]),_c('td',[_vm._v(_vm._s(_vm.$t('device.stock.settings')))]),_c('td',[_vm._v(_vm._s(_vm.$t('device.stock.free')))]),_c('td',[_vm._v(_vm._s(_vm.$t('device.stock.resets')))]),_c('td',[_vm._v(_vm._s(_vm.$t('device.stock.action')))])])]),_c('tbody',_vm._l((_vm.stockinfo),function(val,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(val.no))]),_c('td',[_vm._v(_vm._s(val.stock_save))]),_c('td',[_vm._v(_vm._s(val.stock))]),_c('td',[_vm._v(_vm._s(_vm._f("formatTimer")(val.reset)))]),_c('td',[(
                      _vm.jurisdiction.indexOf('devices-info-stock-change') >= 0
                    )?_c('a',{staticClass:"btn btn-info rounded-pill btn-sm",on:{"click":function($event){return _vm.changestock(val.id, val.stock_save)}}},[_vm._v(_vm._s(_vm.$t('device.stock.change')))]):_vm._e(),(val.codes == 7620 && val.no == 5)?_c('a',{staticClass:"btn btn-info rounded-pill btn-sm",on:{"click":function($event){return _vm.changenoticevalue(val.id, val.save)}}},[_vm._v(" "+_vm._s(_vm.$t('device.stock.notice'))+" ")]):_vm._e(),(
                      val.codes == 7620 &&
                      val.no != 5 &&
                      _vm.jurisdiction.indexOf('devices-info-stock-reset') >= 0
                    )?_c('a',{staticClass:"btn btn-info rounded-pill btn-sm",on:{"click":function($event){return _vm.resetstockv2(val.id)}}},[_vm._v(_vm._s(_vm.$t('device.stock.reset')))]):_vm._e(),(
                      val.codes != 7620 &&
                      _vm.jurisdiction.indexOf('devices-info-stock-reset') >= 0
                    )?_c('a',{staticClass:"btn btn-info rounded-pill btn-sm",on:{"click":function($event){return _vm.resetstock(val.id)}}},[_vm._v(_vm._s(_vm.$t('device.stock.reset')))]):_vm._e()])])}),0)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }